import { ListingType } from '../api/modules/listings/types/listing-base.types'

export type PropertyTypeTab = {
  id: number
  title: string
  image: string
  key: ListingType
}

export const PROPERTY_TYPES: PropertyTypeTab[] = [
  {
    id: 1,
    title: 'Residential',
    image: '/images/background-residential.jpg',
    key: 'residential',
  },
  {
    id: 2,
    title: 'Vacant land',
    image: '/images/background-vacant-land.jpg',
    key: 'land',
  },
  {
    id: 3,
    title: 'Commercial',
    image: '/images/background-commercial.jpg',
    key: 'commercial',
  },
  {
    id: 4,
    title: 'Income property',
    image: '/images/background-income-property.jpg',
    key: 'income',
  },
  {
    id: 6,
    title: 'MLS #',
    image: '/images/background-mls-number.jpg',
    key: 'mls' as any,
  },
]
