import { Box, Text, Select, Button, Flex } from '@chakra-ui/react'
import { Image } from '../../image/image'
import { SearchTerm } from '../../../api/modules/search-terms/types/search-terms.types'
import {
  PropertyTypeTab,
  PROPERTY_TYPES,
} from '../../../constants/property-types'
import { SearchBar } from '../search-bar'

export type Props = {
  selected: PropertyTypeTab
  onChange: (value: PropertyTypeTab) => void
  searchTerms: SearchTerm[]
  propertyType: PropertyTypeTab
}

const Img: React.FC<{ src: string; show: boolean }> = ({ src, show }) => {
  return (
    <Image
      opacity={show ? 1 : 0}
      w="100%"
      h="100%"
      transition="opacity 1s ease-in"
      position="absolute"
      src={src}
      alt="Image of lake side property"
      zIndex={-1}
      fill
      priority={show}
      loadingIndicator={false}
    />
  )
}

export const HomeSearch: React.FC<Props> = ({
  selected,
  onChange,
  searchTerms,
  propertyType,
}) => {
  return (
    <>
      <Box
        w="100%"
        h="100%"
        position="relative"
        top={0}
        left={0}
        bg="gray.800"
        zIndex={-1}
      />
      {PROPERTY_TYPES.map(({ image }) => (
        <Img key={image} src={image} show={selected.image === image} />
      ))}

      <Text mt="64px" textStyle="mainTitle" as="h1" textAlign="center">
        Find Your Northern Michigan Home
      </Text>

      <Flex
        gap={0}
        w="100%"
        mb="16px"
        display={{
          base: 'none',
          sm: 'flex',
        }}
      >
        {PROPERTY_TYPES.map((type) => (
          <Button
            name={type.title}
            borderRadius={0}
            marginInlineStart={0}
            fontWeight={100}
            flex={1}
            color={type.title === selected.title ? 'white' : 'black'}
            bg={type.title === selected.title ? 'blue.500' : 'white'}
            key={type.title}
            _hover={{
              bg: type.title === selected.title ? 'blue.600' : 'gray.200',
            }}
            _active={{
              bg: 'blue.700',
              color: 'white',
            }}
            transition="background .2s ease-in"
            onClick={() => onChange(type)}
            minW="fit-content"
          >
            {type.title}
          </Button>
        ))}
      </Flex>

      <Select
        name="Property type"
        bg="white"
        mb="16px"
        display={{
          base: 'block',
          sm: 'none',
        }}
        value={selected.title}
        onChange={(e) => {
          const option = PROPERTY_TYPES.find((x) => x.title === e.target.value)
          if (option) {
            onChange(option)
          }
        }}
      >
        {PROPERTY_TYPES.map((type) => (
          <option key={type.title}>{type.title}</option>
        ))}
      </Select>

      <SearchBar searchTerms={searchTerms} propertyType={propertyType} />
    </>
  )
}
