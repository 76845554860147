import { Box, Button } from '@chakra-ui/react'
import Link from 'next/link'
import { Advertisement } from '../../api/modules/advertisements/types/advertisement.types'
import { Image } from '../image/image'

type Props = Advertisement

export const AdvertisementBanner: React.FC<Props> = ({ href, image }) => (
  <Link
    href={href}
    passHref
    prefetch={false}
    legacyBehavior
    title="Click here to learn more about our advertiser"
  >
    <Box bg="white" as="a" target="_blank" w="344px" display="block">
      <Image
        src={image}
        alt="advertisement"
        width={344}
        height={206}
        loadingIndicator={false}
      />
      <Box p="16px" display="flex" justifyContent="center">
        <Button aria-label="Click here to learn more" variant="blue">
          Click here to learn more
        </Button>
      </Box>
    </Box>
  </Link>
)
