import { ListItem } from '@chakra-ui/react'
import { Highlight } from '../highlight/highlight'
import { Link } from '../link/link'
import React from 'react'
import { SearchTerm } from '../../api/modules/search-terms/types/search-terms.types'

type Props = {
  isSelected?: boolean
  tabIndex: number
  searchTerm: SearchTerm & { href: string }
  inputValue: string
  onMouseEnter: () => void
  propertyType: string
}

export const SearchTermLink: React.FC<Props> = ({
  isSelected,
  tabIndex,
  searchTerm,
  inputValue,
  onMouseEnter,
  propertyType,
}) => {
  return (
    <ListItem display="flex">
      <Link
        variant="unstyled"
        tabIndex={tabIndex}
        aria-selected={isSelected}
        href={searchTerm.href}
        w="100%"
        p="4px 16px"
        as="a"
        onMouseEnter={onMouseEnter}
        color={isSelected ? 'white' : 'black'}
        background={isSelected ? 'blue.500' : 'white'}
        shallow
      >
        <Highlight
          query={inputValue}
          text={searchTerm.location}
          markProps={{
            py: 1,
            fontWeight: 600,
            color: isSelected ? 'white' : 'black',
          }}
        />
      </Link>
    </ListItem>
  )
}
