import {
  ListingBase,
  ListingType,
} from '../../api/modules/listings/types/listing-base.types'
import { SearchTerm } from '../../api/modules/search-terms/types/search-terms.types'

export const buildUrlFromListing = ({ type, city, id }: ListingBase): string =>
  `/listings/${type.replaceAll(' ', '-')}/city/${city
    .replaceAll(' ', '-')
    .toLowerCase()}/${id}`

export const buildListingUrl = (
  type: ListingType,
  city: string,
  id: string | number
): string =>
  `/listings/${type.replaceAll(' ', '-')}/city/${city
    .replaceAll(' ', '-')
    .toLowerCase()}/${id}`

export const buildListingUrlBasic = (
  type: ListingType,
  id: string | number
): string => `/listings/${type.replaceAll(' ', '-')}/mls/${id}`

const CITY = 'city'

type SearchUrlParams = {
  type: ListingType
  searchTermLocation: string
  pageNumber: number
}
export const searchUrl = ({
  type,
  searchTermLocation,
  pageNumber,
}: SearchUrlParams): string =>
  `/listings/search/${type}${
    searchTermLocation
      ? `/${CITY}/${searchTermLocation.replaceAll(' ', '-').toLowerCase()}`
      : ''
  }${pageNumber === 1 ? '' : `/${pageNumber}`}`

export const buildSearchUrl = (
  propertyType: string,
  { location, type, mlsNumber, city }: SearchTerm
): string => {
  switch (type) {
    case 'address':
      return `/listings/${propertyType.replaceAll(' ', '-')}/city/${
        city || location.replaceAll(' ', '-').toLowerCase()
      }/${mlsNumber}`
    case 'city':
    case 'county':
    case 'township':
    case 'water':
    default:
      return `/listings/search/${propertyType.replaceAll(
        ' ',
        '-'
      )}/${type.replace(' ', '-')}/${location
        .replaceAll(' ', '-')
        .toLowerCase()}`
  }
}
