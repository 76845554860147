import {
  Text,
  Mark,
  useHighlight,
  MarkProps,
  TextProps,
} from '@chakra-ui/react'
import { Fragment } from 'react'

type Props = {
  text: string
  query: string
  markProps?: MarkProps
  textProps?: TextProps
}

export const Highlight: React.FC<Props> = ({
  text,
  query,
  markProps = {},
  textProps = {},
}) => {
  const chunks = useHighlight({
    text,
    query: [query],
  })

  return (
    <Text {...textProps}>
      {chunks.map(({ match, text }, index) =>
        match ? (
          <Mark {...markProps} key={index}>
            {text}
          </Mark>
        ) : (
          <Fragment key={index}>{text}</Fragment>
        )
      )}
    </Text>
  )
}
