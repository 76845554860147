import { Text, TextProps } from '@chakra-ui/react'
import NextLink from 'next/link'

type LinkProps = {
  children: React.ReactNode
  href?: string
  target?: string
  shallow?: boolean
} & TextProps

export const Link: React.FC<LinkProps> = ({
  children,
  href,
  target,
  onClick,
  shallow = false,
  ...textProps
}) => {
  if (onClick) {
    return (
      <Text as="a" variant="link" onClick={onClick} {...textProps}>
        {children}
      </Text>
    )
  }

  if (href) {
    return (
      <NextLink
        href={href}
        passHref
        shallow={true}
        prefetch={false}
        legacyBehavior
      >
        <Text as="a" variant="link" target={target} {...textProps}>
          {children}
        </Text>
      </NextLink>
    )
  }

  throw new Error('Must specify href or onClick')
}
