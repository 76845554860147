import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightAddon,
  List,
  ListItem,
  Spinner,
  Alert,
  AlertIcon,
  useOutsideClick,
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { IoSearch } from 'react-icons/io5'
import { useKeyboardListNavigation } from 'use-keyboard-list-navigation'
import { SearchTerm } from '../../api/modules/search-terms/types/search-terms.types'
import { client } from '../../api/trpc-client'
import { PropertyTypeTab } from '../../constants/property-types'
import {
  buildSearchUrl,
  buildUrlFromListing,
} from '../../utils/url/build-search-url'

import { SearchTermLink } from './search-term-link'

type Props = {
  initialInput?: string
  searchTerms: SearchTerm[]
  propertyType: PropertyTypeTab
  width?: 'auto' | '100%'
  isLoading?: boolean
}

const SEARCH_PLACEHOLDER =
  'Search by Address, City, Township, County, or Body of Water'

export const SearchBar: React.FC<Props> = ({
  searchTerms,
  propertyType,
  width = '100%',
  initialInput = '',
  isLoading = false,
}) => {
  const { push } = useRouter()
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState(initialInput)
  const inputRef = useRef<HTMLInputElement>(null)
  const [mlsId, setMlsId] = useState(0)

  const {
    data: mlsIdData,
    isLoading: mlsIdLoading,
    isError: mlsIdError,
  } = client.listings.baseOne.useQuery(
    {
      id: mlsId,
    },
    {
      enabled: mlsId > 0,
    }
  )

  const searchByMlsId = async (item: SearchTerm) => {
    if (inputValue) {
      const asNumber = Number(inputValue)
      if (Number.isInteger(asNumber)) {
        setMlsId(asNumber)
      }
    }
  }

  useEffect(() => {
    inputValue ? setIsOpen(true) : setIsOpen(false)
  }, [inputValue, setIsOpen])

  const list = searchTerms
    .filter((item) =>
      item.location.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
    )
    .slice(0, 10)
    .map((term) => ({
      ...term,
      href: buildSearchUrl(propertyType.key, term),
    }))

  const {
    index: highlightedIndex,
    selected,
    set,
  } = useKeyboardListNavigation({
    list,
    onEnter: () => {},
  })

  useEffect(() => {
    if (mlsIdData && !mlsIdLoading && !mlsIdError) {
      push(buildUrlFromListing(mlsIdData))
    }
  }, [mlsIdData, mlsIdLoading, mlsIdError, push])

  const selectItem = (item: SearchTerm & { href: string }) => {
    if (inputValue && selected) {
      push(item.href, undefined, { shallow: true })
    }
  }

  useEffect(() => {
    if (inputValue !== initialInput) {
      setInputValue(initialInput)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialInput])

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false)
      }
    }

    window.addEventListener('keyup', handleEsc)

    return () => {
      window.removeEventListener('keyup', handleEsc)
    }
  }, [])

  const listRef = useRef(null)

  useOutsideClick({
    ref: listRef,
    handler: () => setIsOpen(false),
  })

  return (
    <Box
      as={'form' as any}
      w={width}
      onSubmit={(e) => {
        e.preventDefault()
        if (propertyType.id === 6) {
          searchByMlsId(selected)
        } else {
          selectItem(selected)
        }
      }}
      position="relative"
      _before={{
        top: 0,
        left: 0,
        width: '100%',
        content: `"${SEARCH_PLACEHOLDER}"`,
        height: 0,
        px: '38px',
        display: {
          base: 'none',
          sm: 'block',
        },
        whiteSpace: 'pre',
        opacity: 0,
      }}
    >
      <InputGroup size="sm" h="100%">
        <Input
          name="Search location"
          bg="white"
          fontSize="16px"
          h="36px"
          placeholder={SEARCH_PLACEHOLDER}
          size="lg"
          _focus={{
            borderColor: 'blue.500',
          }}
          autoFocus={!inputValue}
          ref={inputRef}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          disabled={mlsId > 0 && mlsIdLoading}
          onFocus={() => setIsOpen(true)}
        />
        <InputRightAddon
          border="none"
          color="white"
          background="blue.400"
          cursor="pointer"
          h="36px"
          p={0}
          _hover={{
            background: 'blue.500',
          }}
        >
          <Button
            disabled={
              isLoading ||
              (mlsId > 0 && mlsIdLoading) ||
              (propertyType.id !== 6 && !selected)
            }
            type="submit"
            variant="unstyled"
            display="flex"
            aria-label="Search"
          >
            {(mlsId > 0 && mlsIdLoading) || isLoading ? (
              <Spinner />
            ) : (
              <IoSearch size={20} />
            )}
          </Button>
        </InputRightAddon>
      </InputGroup>

      {!!inputValue.length &&
        inputValue !== initialInput &&
        !isLoading &&
        !(
          propertyType.id === 6 &&
          (inputValue !== String(mlsId) || mlsIdLoading || mlsIdData)
        ) && (
          <List
            ref={listRef}
            position="absolute"
            zIndex={99}
            background="white"
            boxShadow="0px 4px 14px 4px rgba(0,0,0,0.24)"
            borderRadius="5px"
            mt="6px"
            w="100%"
            py="8px"
            display={isOpen ? 'block' : 'none'}
          >
            <>
              {!list.length && propertyType.id !== 6 && (
                <ListItem px="8px">
                  <Alert status="warning">
                    <AlertIcon />
                    The search location wasn&apos;t recognized. Please try a
                    different location.
                  </Alert>
                </ListItem>
              )}
              {propertyType.id === 6 &&
                mlsIdError &&
                inputValue === String(mlsId) &&
                !mlsIdData && (
                  <ListItem px="8px">
                    <Alert status="warning">
                      <AlertIcon />
                      No listings were found corresponding to the provided MLS
                      number.
                    </Alert>
                  </ListItem>
                )}
              {!isOpen
                ? null
                : list.map((item, index) => {
                    return (
                      <SearchTermLink
                        isSelected={index === highlightedIndex}
                        key={item.location}
                        tabIndex={index}
                        onMouseEnter={() => set({ cursor: index })}
                        inputValue={inputValue}
                        searchTerm={item}
                        propertyType={propertyType.key}
                      />
                    )
                  })}
            </>
          </List>
        )}
    </Box>
  )
}
