import { Flex } from '@chakra-ui/react'
import { GetStaticProps, NextPage } from 'next'
import Head from 'next/head'
import { useState } from 'react'
import { client } from '../api/trpc-client'
import { PROPERTY_TYPES } from '../constants/property-types'
import { AdvertisementBanner } from '../components/advertisement/advertisement-banner'
import { HomeSearch } from '../components/search/home-search/home-search'
import { CACHE_TIME_1_MINUTE } from '../api/constants/cache-control'
import { createSsg } from '../api/create-ssg'
import { distinctRandom } from '../utils/number/distinct-random'

const MAX_ADVERTISEMENTS = 4

type PageProps = {
  trpcState: unknown
  randomIndices: number[]
}

const Page: NextPage<PageProps> = ({ randomIndices }) => {
  const [selected, setSelected] = useState(PROPERTY_TYPES[0])

  const { data: advertisements = [] } = client.advertisements.many.useQuery()

  const { data: baseSearchTerms = [] } = client.searchTerms.many.useQuery()

  const { data: searchTermsAddresses = [] } = client.searchTerms.many.useQuery(
    {
      type: selected.key,
    },
    {
      enabled: selected.id !== 6,
    }
  )

  const allSearchTerms =
    selected.id !== 6 ? baseSearchTerms.concat(searchTermsAddresses) : []

  const adsToShow = randomIndices
    .map((x) => advertisements[x])
    .filter(Boolean)
    .slice(0, MAX_ADVERTISEMENTS)

  return (
    <>
      <Head>
        <title>
          Northern Great Lakes REALTORS® MLS | REALTOR® Association Owned
        </title>
      </Head>

      <Flex
        h="100%"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        margin="0 auto"
        maxW={{
          base: '100%',
          md: '720px',
        }}
        px={{
          base: '16px',
          sm: 0,
        }}
      >
        <HomeSearch
          selected={selected}
          onChange={setSelected}
          searchTerms={allSearchTerms}
          propertyType={selected}
        />

        {!!advertisements?.length && (
          <Flex
            flexDirection={{
              base: 'column',
              sm: 'row',
            }}
            my="64px"
            gap={{
              base: '16px',
              sm: '32px',
            }}
            w={{
              base: '100%',
              sm: '742px',
            }}
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            {adsToShow.map((x) => (
              <AdvertisementBanner key={x.href} {...x} />
            ))}
          </Flex>
        )}
      </Flex>
    </>
  )
}

export const getStaticProps: GetStaticProps<PageProps> = async () => {
  const ssg = await createSsg()

  const advertisements = await ssg.advertisements.many.fetch()

  const randomIndices = distinctRandom(
    advertisements.length,
    advertisements.length - 1
  )

  return {
    props: {
      trpcState: ssg.dehydrate(),
      randomIndices,
    },
    revalidate: CACHE_TIME_1_MINUTE,
  }
}

export default Page
